body {
  font-family: "Open Sans", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.toast-dlg75pct {
  --width: 75%;
}

/**
* CSS for components\layout\Alert.js - Could not make module CSS work...
*/
.AlertCancelButton, .AlertAllowButton, 
#IdgoAlert .alert-wrapper .alert-head h2, 
#IdgoAlert .alert-message {
  color: #033364 !important;
  padding-top: 10px;
}

.fido-alert-1 > .alert-wrapper > .alert-message {
  font-size: 14px;
  color: #666666;
  line-height: 1.5;
  padding-top: 10px;
}