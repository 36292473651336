#ContentDashboard {
  --padding-start: 25px;
  --padding-end: 25px;
  --padding-top: 20px;
  --padding-bottom: 50px;
}

.DashboardPage {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}

.IonItemDivider {
  max-height: 1px;
  min-height: 1px;
  --background: #4A7CB7;
  padding-left: 0px;
  margin-top: 41px;
  margin-bottom: 20px;
}

#TextDivider {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 60px;
  margin-left: auto;
  margin-right: auto;
}

.ContainerText {
  text-align: center;
  color: #606060;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 43px;
}
.HeaderMessage {
  color: #606060;
  font-size: 28px;
  margin-top: 10%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
.HeaderSubMessage {
  color: #606060;
  font-size: 20px;
  margin-top: 15%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}
.ButtonBox {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10%;
}
.ButtonSpacer {
  width: 20%;
}
.ButtonNo {
  font-family: 'Poppins', sans-serif;
}
.ButtonYes {
  font-family: 'Poppins', sans-serif;
}

.ContainerImg {
  width: 95px;
  height: auto;
  display: flex;
  margin: 20px auto 10px auto;
}

.CloseThisWindow {
  color: #4e4e4e;
  font-family: 'Poppins', sans-serif;
  font-weight: lighter;
  font-size: 14px;
  line-height: 1.2;
  margin: 0 auto 0 auto;
  text-align: center;
}

/* ref: https://css-loaders.com/progress/ */
.loadingPage {
  width: 200px;
  height: 15px;
  border-radius: 5px;
  /* 1st gradient colors from favicon.ico */
  background:
    repeating-linear-gradient(135deg,#5590CC 0 10px,#F2F4F4 0 20px) 0/0%   no-repeat,
    repeating-linear-gradient(135deg,#ddd    0 10px,#eee    0 20px) 0/100%;
  animation: lpage 3s infinite;
}
@keyframes lpage {
  100% {background-size:100%}
}
.loadingApprove {
  color: darkgray;
  width: fit-content;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  padding-bottom: 6px;
  background: linear-gradient(currentColor 0 0) 0 100%/0% 3px no-repeat;
  animation: lapprove 3s linear infinite;
}
.loadingApprove:before {
  content:"Loading prompt..."
}
@keyframes lapprove {to{background-size: 100% 3px}}