.Container {
    margin: 5rem auto 5rem auto;
    display: grid;
}

.ContainerText {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color: #606060;
    margin: 0 15px 15px 15px;
    font-size: 22px;
}

.IonButton {
  color: #FFFFFF;
  margin: auto;
  font-size: 24px;
  --background: #4A7CB7;
  --background-activated: #3b6391;
  --background-focused: #3b6391;
  --background-hover: #3b6391;
  --border-radius: 13px;
  height: 56px;
  font-family: 'Poppins', sans-serif;
  font-weight: lighter;
}

.TestIonButton {
  color: #FFFFFF;
  margin: auto;
  margin-top: 30px;
  font-size: 12px;
  --background: gray;
  --background-activated: #3b6391;
  --background-focused: #3b6391;
  --background-hover: #3b6391;
  --border-radius: 6px;
  font-family: 'Poppins', sans-serif;
  font-weight: lighter;
  width: 70%;
}

.ReasonsContainer {
  margin: 10px;
}
.Reasons {
  font-size: 18px;
  font-weight: bold;
}
.ReasonBullet {
  margin-bottom: 10px;
}
.Resolution {
  margin: 5px 10px 10px 10px;
  font-size: 18px;
  font-weight: bold;
}
.ResolutionImage {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 90%;
}
.ProTip {
  margin: 40px 10px 10px 10px;
  font-size: 18px;
  font-weight: bold;
}
.ProTipImage {
  margin-top: 10px;
  margin-bottom: 30px;
  width: 90%;
}