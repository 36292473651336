#ContentSignIn {
  --padding-start: 15px;
  --padding-end: 15px;
  --padding-top: 33px;
  --padding-bottom: 44px;
}

.SignIn {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.BluePanel {
  margin-bottom: 15px;
}

.AgreementsFooter {
  width: 60px;
  height: 60px;
  margin: 40px auto 40px auto;
}

.ContainerImg {
  width: 152px;
  margin: 0 auto auto auto;
}

.ContainerImg .IonImg {
  height: 100%;
  margin: 0px;
}

.ContainerText {
  color: #606060;
  font-family: 'Poppins', sans-serif;
  font-weight: lighter;
  margin-bottom: 43px;
  margin: 0 auto 0 auto;
  text-align: center;
}

.ContainerText p {
  text-align: center;
}

.ContainerText h1 {
  font-size: 30px;
  font-weight: normal;
  line-height: 1.3;
  margin-top: 38px;
  margin-bottom: 0px;
  text-align: center;
}
.ContainerText h2 {
  font-size: 26px;
  font-weight: lighter;
  line-height: 1.2;
  margin-top: 22px;
  margin-bottom: 22px;
  text-align: center;
  padding: 0px;
}
.ContainerText div.header1 {
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
  text-align: center;
  padding: 0px;
  line-height: 1.2;
  margin-top: 22px;
}
.ContainerText div.header2 {
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
  text-align: center;
  padding: 0px;
  line-height: 1.2;
  margin-top: 10px;
}
.ContainerText div.header3 {
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
  text-align: center;
  padding: 0px;
  line-height: 1.2;
  margin-top: 40px;
}

.ContainerText .Agreements {
  font-size: 16px;
  padding: 0 25px 9px 25px;
  margin: 30px auto 15px auto;
}

.Agreements {
  color: #000000;
}

.ContainerInputs {
  height: 20%;
  display: grid;
}

.IonInput {
  border: #606060 1.5px solid;
  min-height: 56px;
  max-height: 56px;
  border-radius: 13px;
  --placeholder-font-weight: initial;
  --placeholder-opacity: .8;
  text-align: center;
  align-self: flex-end;
  margin: auto auto 20px auto;
  width: 290px;
  color: #606060;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: lighter;
  letter-spacing: 5px;
}

.IonButton {
  color: #FFFFFF;
  margin: auto;
  font-size: 26px;
  --background: #4A7CB7;
  --background-activated: #3b6391;
  --background-focused: #3b6391;
  --background-hover: #3b6391;
  --border-radius: 13px;
  height: 56px;
  width: 290px;
  max-width: 290px;
  font-family: 'Poppins', sans-serif;
  font-weight: lighter;
}

.TradeMark {
  font-size: 60%;
  line-height: 0;
  vertical-align: super;  
  font-style: normal;
}

.RequestExpired {
  color: #a00808;
  font-size: 22px;
}

/* ref: https://css-loaders.com/progress/ */
.loadingPage {
  width: 200px;
  height: 15px;
  border-radius: 5px;
  /* 1st gradient colors from favicon.ico */
  background:
    repeating-linear-gradient(135deg,#5590CC 0 10px,#F2F4F4 0 20px) 0/0%   no-repeat,
    repeating-linear-gradient(135deg,#ddd    0 10px,#eee    0 20px) 0/100%;
  animation: lpage 3s infinite;
}
@keyframes lpage {
  100% {background-size:100%}
}

@media only screen and (max-height: 812px) {
  .ContainerText h1 {
    font-size: 28px;
    margin-top: 30px;
  }
  .ContainerText h2 {
    font-size: 22px;
    margin-top: 24px;
  }
  .IonButton {
    font-size: 22px;
  }
  .ContainerText p {
    margin-top: 0px;
  }
  .ContainerText .Agreements {
    font-size: 14px;
    padding: 0 20px 4px 20px;
    margin: 20px auto 10px auto;
  }
  .Agreements {
    color: #000000;
  }
  .AgreementsFooter {
    margin: 30px auto 30px auto;
  }
}
