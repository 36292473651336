@media only screen and (max-height: 812px) {
  .ContainerText h1 {
    font-size: 28px !important;
    margin-top: 20px !important;
  }
  .ContainerText h2 {
    font-size: 22px !important;
  }
  .IonButton {
    font-size: 22px !important;
  }
  .BluePanel {
    margin-bottom: 10px !important;
  }
}

.FidoPage {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#IonContent {
  --padding-start: 15px;
  --padding-end: 15px;
  --padding-top: 33px;
  --padding-bottom: 44px;
}

.ContainerRelayingLogo {
  width: 152px;
  height: 60px;
  margin: 0 auto 0 auto;
}

.ContainerRelayingLogo ion-img {
  width: 100%;
  height: 100%;
}

.PasskeyAttemptSuccess {
  color: #01742d;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  height: 40%;
  line-height: 1.2;
  text-align: center;
}

.PasskeyAttemptFailed {
  color: #6a0101;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  height: 40%;
  line-height: 1.2;
  text-align: center;
}

.CloseThisWindow {
  color: #4e4e4e;
  font-family: 'Poppins', sans-serif;
  font-weight: lighter;
  font-size: 14px;
  line-height: 1.2;
  margin: 0 auto 0 auto;
  text-align: center;
}

.ContainerText {
  margin: 0 auto 0 auto;
  font-family: 'Poppins', sans-serif;
}

.ContainerText h1 {
  color: #606060;
  line-height: 1.3;
  font-size: 28px;
  text-align: center;
  font-weight: 500;
  margin: 40px 20px 24px 20px;
}

.ContainerText h2 {
  color: #787878;
  font-size: 24px;
  text-align: center;
  padding: 0px;
  line-height: 1.5;
  font-weight: 300;
  margin: 30px 10px 20px 10px;
}
.ContainerText h3 {
  color: #787878;
  font-size: 22px;
  text-align: center;
  padding: 0px;
  line-height: 1.5;
  font-weight: 300;
  margin: 15px 4px;
}

.BluePanel {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  justify-content: space-around;
  padding-bottom: 10px;
}

.Buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 auto 0 auto;
}

.PasskeyButton {
  color: #FFFFFF;
  margin: 0px;
  font-size: 26px;
  --background: #4A7CB7;
  --background-activated: #3b6391;
  --background-focused: #3b6391;
  --background-hover: #3b6391;
  --border-radius: 13px;
  margin-bottom: 30px;
  height: 56px;
  width: 290px;
  font-family: 'Poppins', sans-serif;
  font-weight: lighter;
  letter-spacing: normal;
}

.NoPasskeyButton {
  color: #FFFFFF;
  margin: 0px;
  font-size: 22px;
  --background: #bbbbbb;
  --background-activated: #3b6391;
  --background-focused: #3b6391;
  --background-hover: #3b6391;
  --border-radius: 13px;
  margin-bottom: 30px;
  height: 56px;
  width: 290px;
  font-family: 'Poppins', sans-serif;
  font-weight: lighter;
  letter-spacing: normal;
}

.RegisterDeviceButton {
  color: #FFFFFF;
  margin: 0px;
  font-size: 26px;
  --background: #4A7CB7;
  --background-activated: #3b6391;
  --background-focused: #3b6391;
  --background-hover: #3b6391;
  --border-radius: 13px;
  margin-bottom: 30px;
  height: 56px;
  width: 290px;
  font-family: 'Poppins', sans-serif;
  font-weight: lighter;
  letter-spacing: normal;
}

.IonContent {
  display: grid;
  text-align-last: center;
}

.FidoText {
  text-align: center;
  margin: auto .5rem auto .5rem;
}

.IonImg {
  width: 4.5em;
  display: flex;
  margin-top: 2em;
}

.RequestExpired {
  color: #a00808;
  font-size: 22px;
}