.Container {
    margin: 5rem auto 5rem auto;
    display: grid;
}

.ContainerText {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color: #606060;
    margin: 0 15px 15px 15px;
    font-size: 20px;
}

.FeedbackMail {
    --color: #1d6ac2;
    text-decoration: underline;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
}