.IonHeader {
    height: 4rem;
}

.IonToolbar {
    height: inherit;
    display: flex;
    --border-color: #AFB2BF;
}

.ContainerText h4 {
    font-size: 20px;
    width: fit-content;
    margin: auto;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #606060;
}

.IonItem {
    --border-color: #4A7CB7;
    --background-hover: #4A7CB7;
    --background-hover-opacity: .10;
}

.IonLabel {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 300;
    color: #606060 !important;
}

.IonIcon {
    color: #4A7CB7;
}

.PrivacyPolicy {
    --color: #4A7CB7;
    text-decoration: underline;
    margin-bottom: 1.5rem;
    font-size: 12px;
    display: flex;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
}

.TradeMark {
    font-size: 60%;
    line-height: 0;
    vertical-align: super;  
    font-style: normal;
}
