.IonToolbar {
    --border-color: #AFB2BF;
    --border-width: 0 0 1px 0 !important;
}

.IonBackButton {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: lighter;
    --color: #4A7CB7;
}

.IonBackButton#Disabled {
    visibility: hidden;
}

.IonTitle {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: lighter;
    color: #606060;
}

.MenuButton {
    color: #4A7CB7 !important;
}