@media only screen and (max-height: 680px) {
    .ListHeader h2 {
        font-size: 22px !important;
    }

    .IonLabel h4 {
        font-size: 16px !important;
    }

    .IonFooter ion-text h4 {
        font-size: 16px !important;
    }

    .IonItemDivider {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }

    .IonList {
        padding-bottom: 20px !important;
    }
}

@media only screen and (max-height: 812px) {
    #ContentRelyingPartyList {
        --padding-bottom: 0px !important;
        --padding-top: 15px !important;
    }

    .IonItemDivider {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }

    .IonFooter {
        margin-bottom: 44px !important;
    }

    .IonFooter ion-text h4 {
        font-size: 18px !important;
    }
}

#ContentRelyingPartyList {
    --padding-start: 15px;
    --padding-end: 15px;
    --padding-top: 33px;
    --padding-bottom: 44px;
}

.RelyingPartyList {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.IonItemDivider {
    max-height: 1px;
    min-height: 1px;
    width: 60px;
    --background: #4A7CB7;
    padding-left: 0px;
    margin: 20px auto 20px auto;
}

.IonList {
    border-radius: 1rem;
    padding-top: 0px;
}

.ListHeader h2 {
    padding: 0px;
    font-size: 26px;
    line-height: 1.2;
    margin: 0 auto 20px auto;
    font-weight: 300;
    color: #606060;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.IonItem {
    --background: transparent;
    --border-color: #AFB2BF;
    cursor: pointer;
    --padding-end: 20px;
    --padding-start: 20px;
}

.IonLabel h4 {
    font-size: 20px;
    width: fit-content;
    margin: auto auto auto 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    color: #606060;
}

.MenuButton {
  font-size: 40px;
  color: #4A7CB7 !important;
}

.ContainerImg {
    width: 4.5rem;
    margin: auto auto 0px auto;
}

.IonToggle {
    --background-checked: #4A7CB7 !important;
}

.IonFooter {
    text-align: left;
    padding-left: 28px;
    padding-right: 28px;
    background-color: #E0E9F3;
    border-radius: 45px;
    color: #606060;
    align-items: center;
    display: grid;
    grid-auto-flow: column;
}

.IonFooter ion-text h4 {
    margin-left: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    letter-spacing: -.5px;
    margin-bottom: 16px;
    text-align: center;
}

.IonFooter::before{
    width: 0px;
}