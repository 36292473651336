.RpLogo {
  width: 90px;
  height: 56px;
  margin: 5px;
}

.RpAccordion {
  width: calc(100% - 32px);
  margin: 16px auto;
  border-radius: 10px;
}

.RpName {
  padding-left: 10px;
}

.RpIVRNumber {
  color: blue;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}

.dashboardBtn {
  margin: auto;
}

.accordion-expanding,
.accordion-expanded {
  width: 100%;
  margin: 0 auto;
  box-shadow: 5px 5px #888888;
}

.accordion-expanding .RpLogo,
.accordion-expanded .RpLogo {
  width: 100px;
}

.accordion-expanding .RpName,
.accordion-expanded .RpName {
  font-weight: bold;
  font-size: calc(100% + 4px);
}

.accordion-collapsing ion-item[slot="header"],
.accordion-collapsed ion-item[slot="header"] {
  background: whitesmoke;
}

.accordion-expanding ion-item[slot="header"],
.accordion-expanded ion-item[slot="header"] {
  background:	gainsboro;
}

.accordion-expanding ion-list[slot="content"],
.accordion-expanded ion-list[slot="content"] {
  padding: 5px;
  background: gainsboro;
}

.accordion-expanding ion-list[slot="content"] ion-item:first-child,
.accordion-expanded ion-list[slot="content"] ion-item:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.accordion-expanding ion-list[slot="content"] ion-item:last-child,
.accordion-expanded ion-list[slot="content"] ion-item:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
