.BasePage {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ChoiceHeader {
  color: red;
  font-size: 30px;
  font-weight: 500;
  margin-top:20px;
}
.ChoiceParagraph {
  font-weight: 400;
  font-size: 20px;
  margin-top: 30px;
}
.BlockedHeader {
  color: rgb(2, 5, 178);
  font-size: 28px;
  font-weight: 500;
  margin-top:20px;
  margin-bottom: 20px;
}
.BlockedParagraph1 {
  font-weight: 400;
  font-size: 18px;
  margin-top: 30px;
}
.BlockedParagraph2 {
  font-weight: 400;
  font-size: 18px;
  margin-top: 30px;
}
.emphasize {
  font-weight: 600;
  font-size: 18px;
}
.emphasizeNoWrap {
  font-weight: 600;
  font-size: 18px;
  white-space: pre;
}
.ContainerText {
  text-align: center;
  color: #606060;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 43px;
  margin-left:10px;
  margin-right:10px;  
}
.ButtonBox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.ButtonSpacer {
  width: 10%;
}
.ButtonNo {
  font-family: 'Poppins', sans-serif;
}
.ButtonYes {
  font-family: 'Poppins', sans-serif;
}

.Footer {
  margin: auto auto 0 auto;
}
.Footer .IonImg {
  width: 4.5em;
  display: flex;
  margin-top: 2em;
}
