.Container {
    margin: 5rem auto 5rem auto;
    display: grid;
}

.ContainerText {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color: #606060;
    margin: 24px auto 12px auto;
}

.ContainerText p {
    font-weight: 500;
    line-height: 1.2;
    font-size: 18px;
    margin: 0 15px 15px 15px;
}

.List {
    margin: auto;
    width: 85%;
}

.List li::marker {
    color: #606060;
}

.List li p {
    font-size: 16px;
    line-height: 1.2;
    margin-top: 0px;
    font-family: 'Poppins', sans-serif;
    color: #606060;
    font-weight: 300;
}

.IonButton {
    color: #FFFFFF;
    margin: 24px auto 0 auto;
    font-size: 22px;
    --border-radius: 13px;
    height: 56px;
    width: 290px;
    font-family: 'Poppins', sans-serif;
    font-weight: lighter;
    letter-spacing: normal;
}

.IonAlertConfirm {
    color: red;
}