@media only screen and (max-height: 812px) {
  .ContainerText h1 {
    font-size: 28px !important;
  }

  .ContainerText h2 {
    font-size: 22px !important;
  }

  .IonButton {
    font-size: 22px !important;
}
  .ContainerText .Number {
    font-size: 22px !important;
  }
}

.VerificationCode {
  text-align: center;
}

.ContainerText {
  text-align: center;
  color: #606060;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 43px;
}

.ContainerText h1 {
  margin-bottom: 24px;
  line-height: 1.3;
  font-size: 32px;
  font-weight: 400;
}

.ContainerText h2 {
  margin-bottom: 28px;
  margin-top: 0px;
  font-weight: lighter;
}

.ContainerText .Number {
  font-size: 26px;
  color: #4A7CB7;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 0px;
  letter-spacing: 1px;
}

.IonInput {
  margin: 43px auto 20px auto;
  border: #606060 1.5px solid;
  min-height: 56px;
  max-height: 56px;
  border-radius: 13px;
  --placeholder-font-weight: initial;
  --placeholder-opacity: .8;
  text-align: center;
  align-self: flex-end;
  width: 290px;
  color: #606060;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: lighter;
  letter-spacing: 10px;
}

.IonButton { 
  margin: 0px auto 38px auto;
  color: #FFFFFF;
  font-size: 26px;
  --background: #4A7CB7;
  --background-activated: #3b6391;
  --background-focused: #3b6391;
  --background-hover: #3b6391;
  --border-radius: 13px;
  height: 56px;
  width: 290px;
  font-family: 'Poppins', sans-serif;
  font-weight: lighter;
  letter-spacing: normal
}

.IonFooter {
  margin: 0 auto auto auto;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.IonFooter::before {
  content: none !important;
}

.IonFooter h3 {
  margin: 0px auto 13px auto;
  max-width: fit-content;
  display: inline-flex;
  font-family: 'Poppins', sans-serif;
  font-weight: lighter;
  font-size: 15px;
  color: #606060;
}

.IonResendCode {
  height: min-content;
  --background: transparent;
  --color: #4A7CB7;
  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  text-decoration: underline;
  margin: auto 0 auto 0;
  --box-shadow: 0;
  --border-style: none;
  font-family: 'Poppins', sans-serif;
  font-weight: lighter;
  font-size: 15px;
  letter-spacing: 0;
}